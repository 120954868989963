






import { Component, Vue, Emit } from 'vue-property-decorator';
import VueRouter, { Route } from 'vue-router';
import AccountForm from '../../../plugins/AccountForm';
import { GetAccountResponse, ErrorResponse } from '../../../../types/typescript-axios/api';
import axios, {AxiosResponse, AxiosError} from 'axios';
import Error from '../../../components/contents/Error.vue';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
]);
@Component({
  components: {
    Error,
  },
})
export default class EditAccountParent extends Vue {
  private status: number = 200;
  private fromPath: string = '';
  private errorCode: number|null = null;
  private beforeMail: string = '';
  private mail: string = '';
  private mailReInput: string = '';
  private password: string = '';
  private name1: string = ''; // 後で削除
  private name2: string = ''; // 後で削除
  private stepNavi: StepNaviData[] = [
    {
      title: '情報変更',
    },
    {
      title: '本人確認メール送信完了',
    },
    {
      title: 'ご本人確認',
    },
    {
      title: '変更完了',
    },
  ];
  @Emit()
  private setBeforeMail(value: string): void {
    this.beforeMail = value;
  }
  @Emit()
  private setMail(value: string): void {
    this.mail = value;
  }

  @Emit()
  private setMailReInput(value: string): void {
    this.mailReInput = value;
  }

  @Emit()
  private setPassword(value: string): void {
    this.password = value;
  }

  @Emit()
  private setStatus(value: number): void {
    this.status = value;
  }

  @Emit()
  private resetStatusCode(): void {
    this.status = 200;
    this.errorCode = null;
  }

  @Emit()
  private setErrorCode(value: number): void {
    this.errorCode = value;
  }

  private async beforeRouteEnter(to: Route, from: Route, next: any) {
    await axios.get('/wp-json/moc/account')
    .then((response: AxiosResponse<GetAccountResponse>) => {
      next((vm: {
        status: number,
        beforeMail: string,
        name1: string, // 後で削除
        name2: string, // 後で削除
      }) => {
        vm.status = response.status;
        vm.beforeMail = response.data.email;
        vm.name1 = response.data.sei; // 後で削除
        vm.name2 = response.data.mei; // 後で削除
      });
    })
    .catch( (e: AxiosError<ErrorResponse>) => {
      let status = 500;
      if (typeof e.response !== 'undefined') {
        status = e.response.status;
      }
      next((vm: {status: number}) => {
        vm.status = status;
      });
    });
  }
}
